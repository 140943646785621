// LOAD everything
import "@hotwired/turbo-rails"
import {stimulus} from "./stimulus"
import "./front_application.scss"

import WebController from "../pages/web"
import SiteStimulusController from "../pages/stimulus.js"

console.log("Kotaro is here")

stimulus.register("web", WebController)
stimulus.register("site-stimulus", SiteStimulusController)

document.addEventListener('turbolinks:load', function() {
    if (typeof fbq === 'function') {
        fbq('track', 'PageView');
    }
});

// --- start page transisi
let animationType = "blur";
document.addEventListener("click", (event) => {
    const link = event.target.closest("a");
    if (link && link.getAttribute("href")) {
        animationType = link.dataset.animate || animationType;
    }
});
if (animationType === "right") {
    document.addEventListener("turbo:visit", (event) => {
        // Tambahkan class untuk animasi keluar
        const body = document.body;
        body.classList.add("page-leave-right");

        requestAnimationFrame(() => {
            body.classList.add("page-leave-right-active");
        });

        // Tunggu animasi selesai sebelum memulai navigasi
        body.addEventListener(
            "transitionend",
            () => {
                body.classList.remove("page-leave-right", "page-leave-right-active");
            },
            {once: true}
        );
    });

    document.addEventListener("turbo:before-render", (event) => {
        // Tambahkan class untuk animasi masuk
        const newPage = event.detail.newBody;
        newPage.classList.add("page-enter-right");

        requestAnimationFrame(() => {
            newPage.classList.add("page-enter-right-active");
        });

        // Tunggu animasi selesai sebelum membersihkan class
        newPage.addEventListener(
            "transitionend",
            () => {
                newPage.classList.remove("page-enter-right", "page-enter-right-active");
            },
            {once: true}
        );
    });
}
if (animationType === "left") {
    document.addEventListener("turbo:visit", (event) => {
        // Tambahkan class untuk animasi keluar
        const body = document.body;
        body.classList.add("page-leave-left");

        requestAnimationFrame(() => {
            body.classList.add("page-leave-left-active");
        });

        // Tunggu animasi selesai sebelum memulai navigasi
        body.addEventListener(
            "transitionend",
            () => {
                body.classList.remove("page-leave-left", "page-leave-left-active");
            },
            {once: true}
        );
    });

    document.addEventListener("turbo:before-render", (event) => {
        // Tambahkan class untuk animasi masuk
        const newPage = event.detail.newBody;
        newPage.classList.add("page-enter-left");

        requestAnimationFrame(() => {
            newPage.classList.add("page-enter-left-active");
        });

        // Tunggu animasi selesai sebelum membersihkan class
        newPage.addEventListener(
            "transitionend",
            () => {
                newPage.classList.remove("page-enter-left", "page-enter-left-active");
            },
            {once: true}
        );
    });
}
if (animationType === "blur") {
    document.addEventListener("turbo:visit", (event) => {
        // Tambahkan class untuk animasi keluar
        const body = document.body;
        body.classList.add("page-leave-blur");

        requestAnimationFrame(() => {
            body.classList.add("page-leave-blur-active");
        });

        // Tunggu animasi selesai sebelum memulai navigasi
        body.addEventListener(
            "transitionend",
            () => {
                body.classList.remove("page-leave-blur", "page-leave-blur-active");
            },
            {once: true}
        );
    });

    document.addEventListener("turbo:before-render", (event) => {
        // Tambahkan class untuk animasi masuk
        const newPage = event.detail.newBody;
        newPage.classList.add("page-enter-blur");

        requestAnimationFrame(() => {
            newPage.classList.add("page-enter-blur-active");
        });

        // Tunggu animasi selesai sebelum membersihkan class
        newPage.addEventListener(
            "transitionend",
            () => {
                newPage.classList.remove("page-enter-blur", "page-enter-blur-active");
            },
            {once: true}
        );
    });
}
// --- end page transisi

